<template>
  <div>
    <HeaderPanel
      title="Email Template"
      :hasFilter="false"
      :hasDropdown="false"
      :hasSearch="false"
    >
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(topic)="{ item }">
          <router-link :to="'/setting/email-template/' + item.id">
            <span>{{ item.topic }}&nbsp;</span>
            <span style="color: #428bca">{{ renderText(item.id) }} </span>
          </router-link>
        </template>
        <template v-slot:cell(isDefault)="{ item }">
          <div v-if="item.isDefault">
            <font-awesome-icon
              icon="check"
              class="text-success"
            ></font-awesome-icon>
          </div>
        </template>
        <template v-slot:cell(active)="{ item }">
          <span :class="item.active ? 'text-success' : 'text-error'">
            {{ item.active ? "Active" : "Inactive" }}
          </span>
        </template>
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <router-link :to="'/setting/email-template/' + item.id">
              <b-button variant="link" class="text-warning px-1 py-0">
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </router-link>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <FooterAction routePath="/setting" :hideSubmit="true" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "topic",
          label: "Name",
        },

        {
          key: "active",
          label: "Status",
          class: "w-1",
        },
        {
          key: "action",
          label: "Action",
          class: "w-1",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        status: null,
        isDeualt: null,
        lineRichTemplateId: null,
        page: 1,
        pageSize: 10,
      },
    };
  },

  async mounted() {
    await this.getList();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getList() {
      this.isLoading = true;
      const res = await this.axios(`/setting/pdpaemail`);
      console.log(res);
      if (res.data.result === 1) {
        this.items = res.data.detail;
        this.rows = res.data.detail.count;
        this.isLoading = false;
      }
    },
    async filterAction() {
      this.filter.page = 1;
      this.isLoading = true;
      const res = await this.axios.post(`/lineRich`, this.filter);
      if (res.data.result === 1) {
        this.items = res.data.detail.data;
        this.rows = res.data.detail.count;
        this.isLoading = false;
      }
      this.isLoading = false;
    },

    handleChangeTake(value) {
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
      };
    },
    renderText(id) {
      if (id == 34) return "(Notify Customer - Business Hour)";

      return id == 33
        ? "(Notify Customer - Non-Business Hour)"
        : id == 31
        ? "(Notify Agent)"
        : "";
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
